import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';

interface ITASModalProps {
    isOpen: boolean;
    onModalToggle?: (e?: React.MouseEvent<HTMLElement>) => void;
    className: string;
    modalTitle?: React.ReactNode;
    modalBody?: React.ReactNode;
    modalFooter?: React.ReactNode;
}

export default function TASModal(props: ITASModalProps) {
    const { isOpen, onModalToggle, className, modalTitle, modalBody, modalFooter } = props;

    return (
        <Modal
            isOpen={isOpen}
            zIndex={99999}
            applicationNode={'renderPage'}
            autoFocus={true}
            toggle={onModalToggle && onModalToggle}
            className={`tas-modal ${className}`}
        >
            {modalTitle && <ModalHeader>{modalTitle}</ModalHeader>}
            {modalBody && <ModalBody className='tas-modal-body'>{modalBody}</ModalBody>}
            {modalFooter && <ModalFooter>{modalFooter}</ModalFooter>}
        </Modal>
    );
}
